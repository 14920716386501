import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";
import { Grommet } from "grommet";
import { useCloverleafNonce } from "@/cloverleaf-ui/hooks";
import SignInUI from "@/cloverleaf-ui/pages/signin";
import { themeStyle } from "@/cloverleaf-ui/theme";
import { getBaseUrl, queryStringify } from "@/components/utils";
import { getSubdomain } from "@/components/utils/getSubdomain";
import { identify, signout } from "@/lib";
import { initializeApollo } from "@/lib/apolloClient";
import {
  useAuth,
  useDeleteTrust,
  useValidateEmail,
  useLocale
} from "@/lib/hooks";
import { serverSideTranslations } from "@/lib/hooks/useLocale";
import { VALIDATE_ORGANIZATION_QUERY, VALIDATE_ORGANIZATION_STATUS } from "@/lib/hooks/useValidateOrganization";

export function SignInPage({
  addSession = false,
}) {
  const router = useRouter();
  const { t } = useLocale();
  const authenticated = useAuth();
  const queryParams = router.query;

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [, setStoredNonce] = useCloverleafNonce("la_state");

  /**
   * If user date is still in cache, force sign out
   * If this is on a subdomain, redirect to /signin/auth
   */
  React.useEffect(() => {
    // Ignore signout if we are in a multi-auth flow
    if (!router?.query?.flowEntry) {
      // If the user is signed in, sign them out
      if (authenticated) {
        signout();
      }

      // const user = getCurrentUser(this.props);
      // segment(user);
    }
  }, [authenticated, router?.query?.flowEntry]);

  const { validateEmail, validateEmailStatus } = useValidateEmail();

  const { trust: deleteTrust } = useDeleteTrust();

  const handleErrorResponse = ({ status }) => {
    switch (status) {
      case (validateEmailStatus.INVALID):
        setErrorMessage(validateEmailStatus.INVALID);
        break;

      default:
        setErrorMessage(t("unknown-error"));
    }

    return setIsLoading(false);
  };

  const handleOnSubmit = ({ email }) => {
    setIsLoading(true);
    setErrorMessage("");

    return validateEmail({ email })
      .then(({ status, subdomain }) => {
        // This will re-enable the signin button prior to route change but
        // the auth page uses router.back() onCancel so this button will appear disabled unless we reset this state
        setIsLoading(false);

        const ssoDestination = getBaseUrl({
          subdomain,
          path: "/signin/auth",
          query: queryStringify({ ...queryParams, email }),
          locale: router.locale,
        });

        switch (status) {
          case (validateEmailStatus.SUCCESS):
            Sentry.addBreadcrumb({
              category: "signin-flow",
              message: `Validated email: ${email}`,
              level: "info",
            });

            if (subdomain) {
              identify(email, {
                subdomain,
              });

              return window.location = ssoDestination;
            }

            return router.push({
              pathname: "/account/signin/auth",
              query: { ...queryParams, email },
            }, "/signin/auth");

          case (validateEmailStatus.NEED_ORGANIZATION):
            Sentry.addBreadcrumb({
              category: "signin-flow",
              message: `Need organization: ${email}`,
              level: "info",
            });

            identify(email, {
              subdomain,
            });

            return router.push({
              pathname: "/account/signin/find-organization",
              query: { ...queryParams, email },
            });

          default:
            return handleErrorResponse(status);
        }
      })
      .catch(handleErrorResponse);
  };

  const handleOnCancel = router?.query?.flowEntry ? () => {
    if (addSession) {
      setStoredNonce(null);
      deleteTrust({ code: queryParams?.code });
    }

    return router.push(getBaseUrl({
      path: "/my-dashboard",
      subdomain: queryParams?.sourceSubdomain,
      locale: router.locale,
    }));
  } : undefined;

  return (
    <Grommet theme={themeStyle}>
      <SignInUI
        error={errorMessage}
        loading={isLoading}
        onCancel={handleOnCancel}
        onSubmit={handleOnSubmit}
      />
    </Grommet>
  );
}

export async function getServerSideProps(ctx) {
  const translations = await serverSideTranslations(ctx.locale, [
    "common",
  ]);

  // If we are in addSession flow, don't redirect to /signin/auth
  const flowEntry = ctx?.query?.flowEntry;

  if (flowEntry === "AddSession") {
    return {
      props: {
        addSession: true,
        ...translations,
      },
    };
  }

  // If this is a subdomain, try to navigate to the organization's /signin/auth page
  const subdomain = getSubdomain(ctx);

  if (subdomain) {
    const apolloClient = initializeApollo({ ctx });

    const queryParams = ctx.query || {};

    // validate the subdomain
    if (apolloClient) {
      try {
        const { data } = await apolloClient.mutate({
          mutation: VALIDATE_ORGANIZATION_QUERY,
          variables: { input: { subdomain } },
        });

        // if a valid organization
        if (data?.validateOrganization?.status === VALIDATE_ORGANIZATION_STATUS.SUCCESS) {
          // redirect to organization auth page
          return {
            redirect: {
              destination: `/signin/auth?${queryStringify(queryParams)}`,
              permanent: false,
            },
          };
        }
      }
      catch (error) {
        Sentry.captureMessage(error, {
          category: "signin-flow",
          message: `invalid subdomain: ${subdomain}`,
          level: "error",
        });
      }
    }

    // invalid subdomain, or failed to verify organization, redirect to app
    const destination = getBaseUrl({
      path: "/signin",
      query: queryParams,
      locale: ctx.locale,
    });

    return {
      redirect: {
        destination,
        permanent: false,
      },
    };
  }

  return {
    props: {
      ...translations,
    },
  };
}

SignInPage.propTypes = {
  addSession: PropTypes.bool,
};

export default SignInPage;
